@use "sass:map";
@use '@angular/material' as mat;


$dark-primary-text: #546373;


$sortimo-blau: (
  50: #e3f2fd,
  100: #BBDFF5,
  200: #92CCEF,
  300: #49A8E6,
  400: #1F8DD6,
  500: #066AB2,
  600: #004D93,
  700: #023E6A,
  800: #002C4D,
  900: #001A2D,
  A100: #219BD7,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white
  )
);

$sortimo-secondary: (
  50: #C8FFF4,
  100: #70EFDE,
  200: #03DAC5,
  300: #00C4B4,
  400: #00B3A6,
  500: #01A299,
  600: #019592,
  700: #018786,
  800: #017374,
  900: #005457,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

$mat-warn: (
  50 : #fcefe7,
  100 : #f8d8c4,
  200 : #f3be9d,
  300 : #eea475,
  400 : #ea9158,
  500 : #e67d3a,
  600 : #e37534,
  700 : #df6a2c,
  800 : #db6025,
  900 : #d54d18,
  A100 : #ffffff,
  A200 : #ffdfd4,
  A400 : #ffb9a1,
  A700 : #ffa588,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #000000,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$app-primary: mat.define-palette($sortimo-blau, 500);
$app-accent: mat.define-palette($sortimo-blau, A100);

// The warn palette is optional (defaults to red).
$app-warn:  mat.define-palette($mat-warn,);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

@include mat.all-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #066AB2;
  --ion-color-primary-rgb: 6, 106, 178;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #055d9d;
  --ion-color-primary-tint: #1f79ba;

  /** secondary **/
  --ion-color-secondary: #03DAC5;
  --ion-color-secondary-rgb: 3,218,197;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #03c0ad;
  --ion-color-secondary-tint: #1cdecb;

  /** tertiary **/
  --ion-color-tertiary: #023E6A;
  --ion-color-tertiary-rgb: 2,62,106;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #02375d;
  --ion-color-tertiary-tint: #1b5179;

  /** success **/
  --ion-color-success: #066AB2;
  --ion-color-success-rgb: 6,106,178;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #055d9d;
  --ion-color-success-tint: #1f79ba;

  /** warning **/
  --ion-color-warning: #03DAC5;
  --ion-color-warning-rgb: 3,218,197;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #03c0ad;
  --ion-color-warning-tint: #1cdecb;

  /** danger **/
  --ion-color-danger: #B00020;
  --ion-color-danger-rgb: 176,0,32;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #9b001c;
  --ion-color-danger-tint: #b81a36;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  /* .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  } */


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  /* .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  } */
}

  /*
   * Bulma Color Variables
   * -------------------------------------------
   */


@include mat.core();
$primary: map-get($app-primary,default);
$link: map-get($app-accent,default);
$warning: map-get($app-warn, default);
$success: $primary;
$title-color:$dark-primary-text;

html,
body {
  height: 100%;
}
body {
  font-family: Roboto;
  font-weight: Condensed;
  margin: 0;
}



.mat-form-field-infix .mat-form-field-label-wrapper label.ng-star-inserted:not(.mat-empty) {
    color: $primary;
}
@import "~bulma/bulma.sass";
