/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

@mixin stepper-horizontal-line {
  border-top-color: #7C8C9C !important;
  border-top-width: 3px !important;
}

@mixin stepper-vertical-line {
  border-left-width: 3px !important;
  border-left-color: #7C8C9C !important;
  border-left-style: solid !important;
}

body{
  font-family: 'Roboto' !important;
}
html {
  overflow: hidden;
}
.ion-page{
  display: block !important;
  font-family: 'Roboto' !important;
}
.title{
  font-weight: 500 !important;
  font-size: 30px !important;
}
.mat-form-field {
  width: 22em;
}

.mat-checkbox-frame{
  border-color: #0669b2;
}

.mat-header-cell {
  font-size: 13px !important;
  color: #546373;
}

th.mat-header-cell:first-of-type {
  padding-left: 1.5rem !important;
}

td.mat-cell:first-of-type {
  padding-left: 1.5rem !important;
  width: 60px;
  text-align: left !important;
}
.mat-menu-item[disabled]{
  background-color: #f4f1f18f;
}
.mat-cell{
  color: #546373 !important;
}
th.mat-header-cell, td.mat-cell, td.mat-footer-cell{
  padding-left: 10px !important;
}
th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type {
  padding-right: 0px !important;
}

.mat-pseudo-checkbox {
  color: #0669b2 !important;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked {
  background-color: #0e69b2;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background{
  background-color: #0669b2 ;
}
.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background{
  background-color: #C3C8CE ;
}
.back-button {
  padding-left: 0rem !important;
  background-color: transparent !important;
  border-color: transparent !important;
  color: white !important;
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 0 !important;
  width: min-content !important;
}
.mat-tab-body-content {
  overflow: unset !important;
}
.mat-tab-body {
  top: 25px !important;
}
.mat-horizontal-stepper-header{
  pointer-events: none !important;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick{
  color: red !important;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label{
  color: red !important;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: 1px !important;
  color: #ffffff !important;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content{
  left: 10px !important;
}
.mat-badge-small .mat-badge-content {
  min-width: 16px !important;
}
.mat-select-value,.mat-option.mat-active,.mat-option{
  color: #546373 !important;
}
.mat-option:hover{
  color: #ffffff !important;
  background-color: #0669b2 !important;
}

.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  border: 2px solid #7C8C9C !important;
  background-color: white !important;
  color: #0E69B2 !important;

  +.mat-step-label {
    color: #546373 !important;
  }
}

.mat-step-header .mat-step-icon-selected {
  +.mat-step-label {
    color: #0E68B4 !important;
  }
}

.mat-stepper-vertical-line {
  @include stepper-vertical-line();
}

.mat-stepper-horizontal-line {
  @include stepper-horizontal-line();
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before {
  @include stepper-horizontal-line();
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after {
  @include stepper-horizontal-line();
}

.mat-vertical-content{
  padding: 0px 5px 24px 10px !important;
}
.mat-menu-panel{
  margin-top: -40px;
}
.searchbar-input-container{
  border-radius: 30px !important;
  background-color: #ffff !important;
  color: #546373;
}
.title-mobile{
  font-size: 25px !important;
  color: #546373;
}
/* ion-toolbar{
  height: 50px;
} */
.mat-sort-header-ste,.mat-sort-header-arrow {
  display:none !important;
}
@media print {
  body {
     display: block !important;
  }
  ion-header {
     display: none !important;
  }
  body,.app-root,.split-pane,.ion-page,ion-app,ion-nav {
     contain: none;
  }
  body,ion-nav {
     overflow: initial;
  }
  body {
     position: static;
  }
  [ion-content-print] {
     overflow-y: visible !important;
     position: relative;
  }
  th.mat-header-cell:first-of-type{
     padding-left: 0.3rem !important;
  }  
}
.cool-grey-color{
  color: #283546;
}